<template>
  <v-container>
    <vue-snotify></vue-snotify>
    <v-card>
      <v-card-title class="cyan darken-1">
        <span class="white--text">Crear contrato</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <br />
          <v-row>
            <v-col cols="12" md="3"></v-col>
            <v-col cols="12" md="6">
              <v-select v-model="id_customer"  no-data-text="No hay datos disponibles" :rules="customerRules" :items="customers" item-text="legal_name" item-value="id"
                prepend-icon="mdi-store" :disabled="itemDisabled" label="Seleccionar empresa *" persistent-hint>
              </v-select>
              <v-select v-model="id_service" v-on:change="getPlans"  no-data-text="No hay datos disponibles" :rules="serviceRules" :items="services" item-text="name" item-value="id"
                prepend-icon="mdi-text-box-outline" label="Seleccionar Servicio *" persistent-hint></v-select>
              <v-select v-model="id_plan"  no-data-text="No hay datos disponibles" :items="plans" :rules="planRules" item-text="name" item-value="id"
                prepend-icon="mdi-text-box-outline" label="Seleccionar plan *" persistent-hint></v-select>
              <v-select v-model="id_billing_periods"  no-data-text="No hay datos disponibles" :items="billingPeriods" :rules="billingRules" item-text="billing_period" item-value="id"
                prepend-icon="mdi-calendar-month-outline" label="Seleccionar día de facturación *" persistent-hint>
              </v-select>
            </v-col>
            <v-col cols="12" md="3"></v-col>
          </v-row>
          <br /><br />
          <v-divider></v-divider> <br />
          <v-row>
            <v-col cols="12" lg="4"></v-col>
            <v-col cols="12" lg="8" class="text-right">
              <div class="mr-15">
                <v-btn color="primary" outlined class="mr-4 " @click="cancel" >
                  <v-icon>mdi-close-circle</v-icon>
                  <span class="ml-1">Cancelar</span>
                </v-btn>
                <v-btn color="primary"  :disabled="creating"
                :loading="creating"
      
 outlined class=" ml-4" @click="create" >

                  <v-icon>mdi-plus-circle</v-icon><span class="ml-1">Crear</span>
                </v-btn>
              </div>
            </v-col>

          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
import auth from "../../logic/auth";
import Config from "../../config";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    creating:false,
    customers: [],
    services: [],
    plans: [],
    billingPeriods: [],
    itemDisabled: false,
    id_customer: "",
    id_service: "",
    id_plan: "",
    id_billing_periods: "",


    customerRules: [
      v => !!v || 'El cliente es requerido'
    ],
    serviceRules: [
      v => !!v || 'El servicio es requerido'
    ],
    planRules: [
      v => !!v || 'El plan es requerido'
    ],
    billingRules: [
      v => !!v || 'El período es requerido'
    ],



  }),

  mounted() {
    this.selectedCombo();
    this.getBillingPeriods();
    this.getServices();
  },

  methods: {
    cancel() {
      setTimeout(() => this.$router.push({ name: "contracts" }), 10);
    },

    getServices() {
      axios
        .get("/services")
        .then((response) => {
          console.log(response.data);
          this.services = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener servicios contrato");
          setTimeout(() => this.$router.push({ name: "contracts" }), 4000);
        });
    },
    create() {
      console.log("creando contrato");
      this.creating=true;

     
      let request = {
        id_customer: this.id_customer,
        id_plan: this.id_plan,
        id_billing_periods: this.id_billing_periods,
      };

      console.log(request);

      axios
        .post("/save-contract", request)
        .then((response) => {
          this.displayNotification("success", "Éxito", "Se creó correctamente el contrato");
          console.log(response.data);
         if (auth.getUserRut() != Config.rutAdmin){
          setTimeout(() => this.$router.push({ path: `/mis-contratos/${this.id_customer}` }), 3000);
        }
        else
        {
          setTimeout(() => this.$router.push({ path: `/contratos/` }), 3000);
        }
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al crear contrato");
          setTimeout(() => this.$router.push({ name: "contracts" }), 4000);
        });
    },
    selectedCombo() {
      if (auth.getUserRut() != Config.rutAdmin) {
        this.getCustomerByRut();
      } else {
        this.getCustomers();
      }
    },
    getCustomers() {
      axios
        .get("/customers")
        .then((response) => {
          console.log(response.data);
          this.customers = response.data;
          console.log("datos del usuario autenticado");
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener clientes");
        });
    },
    getCustomerByRut() {
      let request = {
        rut: auth.getUserRut(),
      };
      axios
        .post("/customer-by-rut", request)
        .then((response) => {
          console.log(response.data);
          this.customers = response.data;
          console.log("datos del usuario autenticado por rut");
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener clientes");
        });
    },
    getPlans() {
      this.id_plan = "";
      this.plans = [];
      if (this.id_service != "" && parseInt(this.id_service) > 0) {
        let request = {
          id_service: this.id_service,
        };
        axios
          .post("/plan-by-service", request)
          .then((response) => {
            console.log(response.data);
            this.plans = response.data;
          })
          .catch((err) => {
            console.log(err, "error");
            this.displayNotification("error", "Error", "Error al obtener planes");
          });
      }
    },
    getBillingPeriods() {
      axios
        .get("/billing-periods")
        .then((response) => {
          console.log(response.data);
          this.billingPeriods = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener días de facturación");
        });
    },
  },
};
</script>